$(".hamburger-menu-button").on("click", function() {
      var x = document.getElementById("hamburger-menu").getAttribute("aria-expanded"); 
    if (x == "true") 
    {
    x = "false";
    document.getElementById("ham-menu").innerHTML = "Menu";
    } else {
    x = "true"
    document.getElementById("ham-menu").innerHTML = "Close Menu";
    }
    document.getElementById("hamburger-menu").setAttribute("aria-expanded", x);
    });


$ (document).ready (function () {
  var button = document.getElementById ('hamburger-menu'),
    span = button.getElementsByTagName ('span')[0];
  var boolean = false;
  button.onclick = function () {
    span.classList.toggle ('hamburger-menu-button-close');
  };
  $ ('#hamburger-menu').on ('click', toggleOnClass);
  var phonedoc = document.getElementsByClassName ('show-on-mobile');
  for (var i = 0; i < phonedoc.length; i++) {
    var op = phonedoc[i].getElementsByTagName ('a');
    for (j = 0; j < op.length; j++) {
      // console.log(op[j].innerText);
      op[j].href = 'tel:' + op[j].innerText + '';
    }
  }
  function toggleOnClass (event) {
    var toggleElementId = '#' + $ (this).data ('toggle'),
      element = $ (toggleElementId);
    element.toggleClass ('show');

    $ ('#hamburger-menu').blur ();

    boolean = !boolean;
    if (boolean) {
      $ ('.secondary-header').css ('height', '100%');
      $ ('html').css ('overflow-y', 'hidden');
      $ (document).find ('.navbar-expand-lg').attr ('role', 'dialog');
      document
        .getElementsByClassName ('main-menu')[1]
        .children[0].children[0].focus ();
    } else {
      $ ('.secondary-header').css ('height', 'auto');
      $ ('html').css ('overflow-y', 'auto');
      $ (document).find ('.navbar-expand-lg').removeAttr ('role', 'dialog');
    }
  }

  // close hamburger menu after click a
  $ ('.menu li a').on ('click', function () {
    $ ('#hamburger-menu').click ();
  });
  fhlb_cardlayoutFn ();
  fhlb_stickyfunc ();

  if ($ ('#form-or-application-list').val () != 'Select your form') {
    $ ('#SelectedMPFListOption_BTN').removeAttr ('disabled');
  } else {
    $ ('#SelectedMPFListOption_BTN').attr ('disabled', 'disabled');
  }

  $ ('#form-or-application-list').change (function () {
    if ($ ('#form-or-application-list').val () != 'Select your form') {
      $ ('#SelectedMPFListOption_BTN').removeAttr ('disabled');
    } else {
      $ ('#SelectedMPFListOption_BTN').attr ('disabled', 'disabled');
    }
  });

  if ($ ('#funding-goal-list').val () != 'Select Funding Goal') {
    $ ('#SelectedFunding_GoalOption_BTN').removeAttr ('disabled');
  } else {
    $ ('#SelectedFunding_GoalOption_BTN').attr ('disabled', 'disabled');
  }

  $ ('#funding-goal-list').change (function () {
    if ($ ('#funding-goal-list').val () != 'Select Funding Goal') {
      $ ('#SelectedFunding_GoalOption_BTN').removeAttr ('disabled');
    } else {
      $ ('#SelectedFunding_GoalOption_BTN').attr ('disabled', 'disabled');
    }
  });
});

setTimeout (function () {
  $ ('.pl-carousel-layout btn btn-link pl-carousel-next pl-carousel-control').attr ('tabindex', '0');
}, 500);

function resizingFunc () {
  var minDesktopWidth = window.innerWidth;
   var isMenuVisible=false;
  if (minDesktopWidth > 991) {
      $(document).keyup(function(e) {
      if (e.keyCode ===27) {
      if(isMenuVisible){
      $('.navbar .dropdown .dropdown-menu').removeClass('hidden show').addClass('hidden');
     $('.secondary-header li.main-menu > .dropdown-nav > a.nav-link').css({"border-color":"transparent"});
      isMenuVisible=false;
      }
      }
      });

      $('.navbar .dropdown').mouseover(function() {
      if(!isMenuVisible){
      $(this).find('.dropdown-menu').removeClass('hidden show').addClass('show')
      $('.secondary-header li.main-menu > .dropdown-nav > a.nav-link').removeAttr('style');
      isMenuVisible=true;
      }
      });

      $('.navbar .dropdown').mouseleave(function() {
        if(isMenuVisible){
        $('.navbar .dropdown .dropdown-menu').removeClass('hidden show').addClass('hidden');
           isMenuVisible=false;
            } 
     });

      
    var myListener = function () {
      removeSubMenu ();
    };

    var hideModalKeyPress = function (e) {
      if (e.keyCode === 27) {
        removeSubMenu ();
      } else if (e.keyCode === 39) {
        //"right arrow pressed inside hidekeypress function"
      }
    };

    //document.getElementsByClassName("dropbtn").setAttribute("aria-expanded", "false");

    $ ('.dropbtn').attr ('aria-expanded', 'false');

    function removeSubMenu () {
      $ ('.dropdown-focus').removeClass ('dropdown-focus');
      $ ('.dropdown-menu-focus').removeClass ('dropdown-menu-focus');
      $ ('.dropbtn').attr ('aria-expanded', 'false');
      document.removeEventListener ('keydown', hideModalKeyPress, false);
      document.removeEventListener ('mousemove', myListener, false);
    }

    function skipToMainContent () {
      document.getElementById ('mainContent').focus ();
    }

    $ ('.dropdown a').keydown (function (e) {
      if (e.keyCode === 40) {
        //"down arrow pressed"
        addSubMenu ();
        $ (this)
          .parent ('.dropdown-nav')
          .find ('.dropdown-menu ul li:first-child a')
          .focus ();
      }
    });

    $ ('.dropdown-menu li:first-child a').keydown (function (e) {
      if (e.keyCode === 38) {
        //"parent up arrow pressed"
        $ (this).parents ('.dropdown').children ().find ('.dropbtn').focus ();
        removeSubMenu ();
      }
    });

    $ ('.dropdown-menu li:last-child a').keydown (function (e) {
      if (e.keyCode === 40 || e.keyCode === 39) {
        // "parent down or righht arrow pressed"
        $ (this).parents ('.dropdown').children ().find ('.dropbtn').focus ();
        removeSubMenu ();
      }
    });

    var toplevelAnchor = $ ('.dropdown-menu a');
    toplevelAnchor.keydown (function (e) {
      if (e.keyCode === 39) {
        // "right arrow pressed"
        $ (this).parent ().next ().children ().focus ();
      }

      if (e.keyCode === 37) {
        // "left arrow pressed"
        $ (this).parent ().prev ().children ().focus ();
      }

      if (e.keyCode === 38) {
        // "up arrow pressed"
        $ (this).parent ().prev ().children ().focus ();
      }

      if (e.keyCode === 40) {
        // "down arrow pressed"
        $ (this).parent ().next ().children ().focus ();
      }
    });

    function addSubMenu () {
      $ (this).parents ('.dropdown').addClass ('dropdown-focus');
      $ (this).parents ('.dropdown-menu').addClass ('dropdown-menu-focus');
      $ (this).siblings ('.dropbtn').attr ('aria-expanded', 'true');
      document.addEventListener ('mousemove', myListener, false);
      document.addEventListener ('keydown', hideModalKeyPress, false);
    }

    $ (function () {
      $ ('.dropdown a').focus (function () {
        $ (this).parents ('.dropdown').addClass ('dropdown-focus');
        $ (this).parents ('.dropdown-menu').addClass ('dropdown-menu-focus');
        $ (this).attr ('aria-expanded', 'true');
        document.addEventListener ('mousemove', myListener, false);
        document.addEventListener ('keydown', hideModalKeyPress, false);
      });

      $ ('.dropdown a').mouseenter (function () {
        $ (this).attr ('aria-expanded', 'true');
      });
      $ ('.dropdown a').mouseleave (function () {
        $ (this).attr ('aria-expanded', 'false');
      });

      $ ('.dropdown-menu').mouseenter (function () {
        $ (this).siblings ('.dropbtn').attr ('aria-expanded', 'true');
      });
      $ ('.dropdown-menu').mouseleave (function () {
        $ (this).siblings ('.dropbtn').attr ('aria-expanded', 'false');

         if(isMenuVisible){
          $('.navbar .dropdown .dropdown-menu').removeClass('hidden show').addClass('hidden');
            isMenuVisible=false;
             }
      });
      // $ ('.dropdown-nav .dropbtn .dropdown-menu').mouseover (function () {
      //   if($('.dropdown-nav .dropbtn').attr('aria-expanded') === true){
      //     if(e.keyCode === 27) {
      //       $(".dropdown-toggle").dropdown("toggle");
      //       $ ('.dropdown-nav .dropbtn').attr ('aria-expanded', 'false');
      //     }
      //   }
      // });



      // Sub Menu
      $ ('.dropdown-menu a').focus (function (e) {
        $ (this).parents ('.dropdown').addClass ('dropdown-focus');
        $ (this).parents ('.dropdown-menu').addClass ('dropdown-menu-focus');
        $ (this).parents ('.dropbtn').attr ('aria-expanded', 'true');
        document.addEventListener ('mousemove', myListener, false);
        document.addEventListener ('keydown', hideModalKeyPress, false);
      });

      $ ('.header-login-btn').focus (function () {
        removeSubMenu ();
      });

      $ ('.fhl-logo a').focus (function () {
        removeSubMenu ();
      });

      $ ('.remove-menu-focus').focus (function () {
        removeSubMenu ();
      });

      setTimeout (function () {
        $ ('.pl-carousel-layout .pl-carousel-next').attr ('tabindex', '0');
      }, 500);
    });
  } else if (minDesktopWidth < 991) {
    $('.navbar .dropdown').off('mouseover mouseenter mouseleave mouseup mousedown');
    $(' .mob-arrow-link ').on('click');
    $(document).on ('keydown', function (e) {
      var target = e.target;
      var shiftPressed = e.shiftKey;
      if (e.keyCode == 9) {
        if ($ (target).parents ('[role=dialog]').length) {
          var borderElem = shiftPressed
            ? $ (target).closest ('[role=dialog]').find ('#temp')
            : $ (target).closest ('[role=dialog]').find ('a').last ();

          if ($ (borderElem).length) {
            if ($ (target).is ($ (borderElem))) {
              if (shiftPressed) {
                return false;
              } else {
                $ (target)
                  .closest ('[role=dialog]')
                  .find ('#temp')
                  .attr ('tabindex', '0')
                  .focus ();
              }
            } else {
              return true;
            }
          }
        }
      }
      return true;
    });
  }
}

resizingFunc ();

$ (window).resize (function () {
  resizingFunc ();
});

window.onscroll = function () {
  fhlb_stickyfunc ();
};
$ (document).on ('click', function () {
  if ($ ('.selbtngroup').length === 1) {
    $ ('table.header .headerbtncell').first ().children ().focus ();
    //datevalue currmonth$(".datevalue").attr("role", "button");
    // Set the Calendar Div Height
    if ($ ('.selector .caltable').length === 1) {
      var getCalendar_TableHeight = $ ('.selector table.caltable').height ();
      var getCalendar_HeaderHeight = $ ('.selector table.header').height ();
      $ ('.selector').css (
        'height',
        getCalendar_TableHeight + getCalendar_HeaderHeight
      );
    }
    removeTabIndex ();
  }

  $ ('.headerlabelbtn').click (function () {
    removeTabIndex ();

    $ ('.yearchangebtn.icon-mydpup').click (function () {
      removeTabIndex ();
      yearSelected ();
    });
    $ ('.yearchangebtn.icon-mydpdown').click (function () {
      removeTabIndex ();
      yearSelected ();
    });

    var monthKeyPressed = $ ('.monthcell');
    monthKeyPressed.keydown (function (e) {
      if (e.keyCode === 13) {
        removeTabIndex ();
      }
      if (e.keyCode === 32) {
        removeTabIndex ();
      }
    });
    yearSelected ();
  });
  function yearSelected () {
    var yearKeyPressed = $ ('.yearcell');
    yearKeyPressed.keydown (function (e) {
      if (e.keyCode === 13) {
        removeTabIndex ();
      }
      if (e.keyCode === 32) {
        removeTabIndex ();
      }
    });
  }
  function removeTabIndex () {
    $ ('.daycell.disabled').removeAttr ('tabindex');
    $ ('.monthcell.disabled').removeAttr ('tabindex');
    $ ('.yearcell.disabled').removeAttr ('tabindex');
    $ ('.daycell .datevalue.prevmonth').parent ().removeAttr ('tabindex');
    $ ('.daycell .datevalue.nextmonth').parent ().removeAttr ('tabindex');
    dayArrowPress ($ ('.daycell.currmonth'), 1, 5, 0);
    dayArrowPress ($ ('.monthcell.tablesinglemonth'), 0, 2, 0);
    dayArrowPress ($ ('.yearcell.tablesingleyear'), 0, 4, 1);
    var weekDayTitle = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    $ ('.datevalue.currmonth').attr ('role', 'button');

    var dayLabel = document.querySelectorAll ('.daycell.currmonth');
    for (var i = 0; i < dayLabel.length; i++) {
      dayLabel[i].setAttribute (
        'aria-label',
        weekDayTitle[dayLabel[i].cellIndex] +
          ' ' +
          $ ('.headerlabelbtn.monthlabel').text () +
          ' ' +
          dayLabel[i].innerText +
          ', ' +
          $ ('.headerlabelbtn.yearlabel').text ()
      );
    }

    var monthLabel = document.querySelectorAll ('.monthcell.tablesinglemonth');
    for (var i = 0; i < monthLabel.length; i++) {
      monthLabel[i].setAttribute (
        'aria-label',
        monthLabel[i].innerText + ' ' + $ ('.headerlabelbtn.yearlabel').text ()
      );
    }
    var yearLabel = document.querySelectorAll ('.yearcell.tablesingleyear');
    for (var i = 0; i < yearLabel.length; i++) {
      yearLabel[i].setAttribute (
        'aria-label',
        ' Selected Year ' + yearLabel[i].innerText
      );
    }
  }

  function dayArrowPress (arrowPressed, start, end, defaultLength) {
    arrowPressed.keydown (function (e) {
      if (e.keyCode === 39) {
        // "right arrow pressed"
        if ($ (this)[0].cellIndex == end) {
          if ($ (this).parent ().next ().children ().length != 0) {
            $ (this).parent ().next ().children ()[start].focus ();
          }
        } else {
          $ (this).next ().focus ();
        }
      }
      if (e.keyCode === 37) {
        // "left arrow pressed"
        if ($ (this)[0].cellIndex == start) {
          if ($ (this).parent ().prev ().children ().length != defaultLength) {
            $ (this).parent ().prev ().children ()[end].focus ();
          }
        } else {
          $ (this).prev ().focus ();
        }
      }
      if (e.keyCode === 38) {
        // "up arrow pressed"
        e.preventDefault ();
        if ($ (this).parent ().prev ().children ().length != defaultLength) {
          if (
            $ (this).parent ().prev ().children ().length >=
            $ (this)[0].cellIndex
          ) {
            $ (this)
              .parent ()
              .prev ()
              .children ()[$ (this)[0].cellIndex]
              .focus ();
          } else {
            $ (this)
              .parent ()
              .prev ()
              .children ()[$ (this).parent ().prev ().children ().length]
              .focus ();
          }
        }
      }
      if (e.keyCode === 40) {
        // "down arrow pressed"
        e.preventDefault ();
        if ($ (this).parent ().next ().children ().length != defaultLength) {
          if (
            $ (this).parent ().next ().children ().length >=
            $ (this)[0].cellIndex
          ) {
            $ (this)
              .parent ()
              .next ()
              .children ()[$ (this)[0].cellIndex]
              .focus ();
          } else {
            $ (this)
              .parent ()
              .next ()
              .children ()[$ (this).parent ().next ().children ().length]
              .focus ();
          }
        }
      }
    });
  }
});

window.addEventListener ('resize', fhlb_cardlayoutFn);
function fhlb_cardlayoutFn () {
  if (window.innerWidth > 767) {
    var maxHeight = -1;
    var headerHeight = -1;
    var contentHeight = -1;
    var linkHeight = -1;

    $ (
      'div.card-layout-container > .card-layout-middle-layer > .card-layout-index-header'
    ).each (function () {
      if ($ (this).height () > headerHeight) {
        headerHeight = $ (this).height ();
      }
    });
    $ (
      'div.card-layout-container > .card-layout-middle-layer > .card-layout-index-content'
    ).each (function () {
      if ($ (this).height () > contentHeight) {
        contentHeight = $ (this).height ();
      }
    });
    $ (
      'div.card-layout-container > .card-layout-middle-layer > card-layout-index-link'
    ).each (function () {
      if ($ (this).height () > linkHeight) {
        linkHeight = $ (this).height ();
      }
    });
    maxHeight = 40 + headerHeight + contentHeight + linkHeight;
    $ ('div.card-layout-container > .card-layout-middle-layer').height (
      maxHeight
    );
  } else {
    $ ('div.card-layout-container > .card-layout-middle-layer').css (
      'height',
      'auto'
    );
  }
}

var header = document.getElementsByClassName (
  'container-fluid text-center secondary-header p-0 stickyHeader'
);
var sticky;
for (var i = 0; i < header.length; i++) {
  sticky = header[i].offsetTop;
}

function fhlb_stickyfunc () {
  if (window.pageYOffset > sticky) {
    for (var i = 0; i < header.length; i++) {
      header[i].className =
        'container-fluid text-center secondary-header p-0 stickyHeader sticky';
      $ ('.secondary-header').css (
        'box-shadow',
        '0 4px 3px -2px rgba(0, 0, 0, 0.18)'
      );
    }
  } else {
    for (var i = 0; i < header.length; i++) {
      header[i].className =
        'container-fluid text-center secondary-header p-0 stickyHeader';
      $ ('.secondary-header').css ('box-shadow', 'none');
    }
  }
}

/***********Generic Script for Advance and Forms + Application Filters********** */

function find_filter (id) {
  // get and set requested dropdown ID
  var select_element = document.getElementById (id);
  // get and set requested dropdown Selected Index
  var filterName = select_element.options[select_element.selectedIndex].value;
  // declaring local variables
  var filer_value;
  var url_Value;
  if (id == 'funding-goal-list') {
    filer_value = 'advanceFilter';
    url_Value = '/products-programs/advances';
  } else if (id == 'form-or-application-list') {
    filer_value = 'formorapplicationFilter';
    url_Value = '/member-resources/forms-applications';
  }
  // set localstorage value from requested dropdown selected value
  localStorage.setItem (filer_value, filterName);
  // Constructing the dynamic url
  var urlFHLB =
    window.BB.config.linkRoot + '/' + window.BB.config.portalName + url_Value;
  // Attach the above url and redirect to relevant page
  window.location.href = urlFHLB;
}

function searchResult () {
  var serachResultURL =
    window.BB.config.linkRoot +
    '/' +
    window.BB.config.portalName +
    '/search-result';
  window.location.href = serachResultURL;
}
/* End of Find the Advance  List**/

/* Looking for Different Advance */
var AdvanceList = [
  'Amortizing Advance',
  'Callable SOFR-Indexed Floater Advance',
  'Classic Advance',
  'Community Development Advance',
  'Community Development Advance Extra',
  'Daily Cash Manager Advance',
  'Discount Note Auction-Floater Advance',
  'Forward Starting Advance',
  'HLB-Option Advance',
  'IDEAL Cash Manager Advance',
  'Member-Option Advance',
  'New England Fund Advance',
  'Rollover Cash Manager Advance',
  'SOFR Flipper Advance',
  'SOFR-Indexed Advance',
  'Symmetrical Prepayment Advance'
];
html = "<option value='Select Advance'>Select Advance</option>";
var selectAdvanceList = document.getElementById ('select-new-advance');
var homeFundingList = document.getElementById ('funding-goal-list');
var mpfFundingList = document.getElementById ('form-or-application-list');
if (homeFundingList != null) {
  window.scrollTo (0, 0);
}
if (mpfFundingList != null) {
  window.scrollTo (0, 0);
}
for (var i = 0; i < AdvanceList.length; i++) {
  html +=
    '<option value=' + AdvanceList[i] + '>' + AdvanceList[i] + '</option>';
}
if (selectAdvanceList != null) {
  document.getElementById ('select-new-advance').innerHTML = html;
  document.getElementById ('select-advance-button').disabled = true;
}

function selectedAdvance () {
  var e = document.getElementById ('select-new-advance');
  var selectedAdvance = e.options[e.selectedIndex].text;
  if (selectedAdvance == 'Select Advance') {
    document.getElementById ('select-advance-button').disabled = true;
  } else {
    document.getElementById ('select-advance-button').disabled = false;
  }
}
function lookingAdvance () {
  var e = document.getElementById ('select-new-advance');
  var advanceFilter = e.options[e.selectedIndex].text;
  if (advanceFilter != 'Select Advance') {
    var advanceURL =
      window.BB.config.linkRoot +
      '/' +
      window.BB.config.portalName +
      '/' +
      advanceFilter.toLowerCase ().split (' ').join ('-');
    window.location.href = advanceURL;
  }
}

/*************List of MPF Direct Options****************/

/* Looking for Different Advance */
var MPFDirectList = ['MPF Conventional Loans', 'MPF Government Guaranteed'];
html = "<option value='Select MPF'>Select MPF</option>";
var selectMPFDirectList = document.getElementById ('select-new-mpf');
for (var i = 0; i < MPFDirectList.length; i++) {
  html +=
    '<option value=' + MPFDirectList[i] + '>' + MPFDirectList[i] + '</option>';
}
if (selectMPFDirectList != null) {
  document.getElementById ('select-new-mpf').innerHTML = html;
  document.getElementById ('select-new-mpf-button').disabled = true;
}

function selectedMPFOption () {
  var e = document.getElementById ('select-new-mpf');
  var selectedMPFOption = e.options[e.selectedIndex].text;
  if (selectedMPFOption == 'Select MPF') {
    document.getElementById ('select-new-mpf-button').disabled = true;
  } else {
    document.getElementById ('select-new-mpf-button').disabled = false;
  }
}
function lookingMPFOption () {
  var e = document.getElementById ('select-new-mpf');
  var mpfDirect_Filter = e.options[e.selectedIndex].text;
  if (mpfDirect_Filter != 'Select MPF') {
    var mpfDirectURL =
      window.BB.config.linkRoot +
      '/' +
      window.BB.config.portalName +
      '/' +
      mpfDirect_Filter.toLowerCase ().split (' ').join ('-');
    window.location.href = mpfDirectURL;
  }
}
